import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const InboxWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/InboxWrapper/InboxWrapper"),
    ),
);
const ThreadChat: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../components/ThreadChat/ThreadChat"),
    ),
);

const inboxRoutes: routeType[] = [
  {
    path: "/inbox/:inboxType",
    element: <InboxWrapper />,
    // permissions: "GET_MICRODIMENSIONS",
    meta: {
      title: "Inbox",
      layout: "app",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <></>,
        meta: {
          type: "component",
          private: true,
          company: true,
        },
      },
      {
        path: ":threadId",
        element: <ThreadChat inboxLocation="INBOX" />,
        meta: {
          type: "component",
          private: true,
          company: true,
        },
      },
    ],
  },
];

export default inboxRoutes;
