import { publicHttp } from "common/api/public-http";

export const resendEmail = async ({
  applicationId,
}: {
  applicationId: string;
}) =>
  await publicHttp.post(
    `/application/${applicationId}/resend-verification-email`,
  );

export const fetchPositionApplicationPageContent = async (id: string) => {
  return await publicHttp.get(`/positions/${id}/public`);
};

export const fetchTemplateApplicationPageContent = async (id: string) => {
  return await publicHttp.get(`/positions/template/${id}/public`);
};
