// ** Axios Imports
import { http } from "common/api/http";

export const fetchPosition = async (id: string) => {
  return await http.get(`/positions/${id}`);
};

export const fetchPositionTemplate = async (id: string) => {
  return await http.get(`/positions/template/${id}`);
};

export const fetchPositionApplicationPageContent = async (id: string) => {
  return await http.get(`/positions/${id}/public`);
};

export const fetchPositionContents = async (id: string) => {
  return await http.get(`/tests/${id}/contents`);
};

export const fetchTestOptions = async () => {
  return await http.get(`/tests?limit=100&page=1&state=published`);
};

export const duplicateTest = async (id: string) => {
  return await http.post(`/tests/${id}/duplicate`);
};

export const getTestDemoQuestions = async (testId: string) => {
  return await http.get(`/tests/${testId}/questions?isDemo=true`);
};
