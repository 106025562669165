// ** Redux Imports
import { createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import { http } from "common/api/http";
import { setToast } from "redux/alert";
import { RootState } from "../../../../redux/store";

interface getTestsParams {
  companyId?: string;
  paramsArray?: any;
  signal?: any;
}

type multiSelectType = {
  _id: string;
};

export const getPositions = createAsyncThunk<any, getTestsParams>(
  "positions/getPositions",
  async (
    { companyId, paramsArray, signal },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const params = {
        ...(paramsArray?.filters?.company && {
          companyId: paramsArray?.filters.company?.value,
        }),
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.tests && {
          tests: `${paramsArray?.filters.tests.map((tests: multiSelectType) => tests._id)}`,
        }),
        ...(paramsArray?.sort && {
          sort: paramsArray?.sort,
        }),
        page: paramsArray?.page || 1,
        ...(paramsArray?.filters?.state &&
          paramsArray?.filters?.state !== "all" && {
            state: `${paramsArray?.filters?.state ? paramsArray?.filters?.state.toUpperCase() : "ACTIVE"}`,
          }),
        limit: paramsArray?.limit || 10,
        ...(companyId && {
          company: companyId,
        }),
        ...((paramsArray?.filters?.followerIds === "notFollowed" ||
          paramsArray?.filters?.followerIds?.length) && {
          followersIds:
            paramsArray?.filters?.followerIds === "notFollowed"
              ? "null"
              : `${paramsArray?.filters?.followerIds.map((item: string) => {
                  return item;
                })}`,
        }),
      };
      const response = await http.get(`/positions`, {
        params,
        signal,
      });
      if (!response?.data) return;
      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export interface positionWizardInput {
  name: string;
  hiringLimit: number;
  employmentType: string;
  workingFrom: string;
  location?: string;
  companyId?: string;
  requirements?: {
    licenses?: { importance: number; licenses: string[] };
    industryExperience?: { importance: number; industries: string[] };
    workExperience?: { from: number; to: number; importance: number };
    education?: {
      level: string;
      fieldOfStudy: string;
      importance: number;
    };
  };
  jobDescription?: string;
  jobSummary?: string;
  keyResponsibilities?: string;
}

export const createPosition = createAsyncThunk(
  "positions/createPosition",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post("/positions", data);
      dispatch(
        setToast({
          message: "Successfully created a position.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  },
);

// POSITION TEMPLATES
interface getTemplatesParams {
  paramsArray?: any;
  signal?: any;
  page: number;
  companyId?: string;
}
export const getPositionTemplates = createAsyncThunk<
  any,
  getTemplatesParams,
  { state: RootState }
>(
  "positions/getPositionTemplates",
  async (
    { paramsArray, signal, page, companyId },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(paramsArray?.search && {
          search: paramsArray?.search,
        }),
        ...(paramsArray?.filters?.sort && {
          sort: `${paramsArray?.filters?.direction?.value === "desc" ? "-" : ""}${
            paramsArray?.filters.sort.value
          }`,
        }),
        ...(paramsArray?.filters?.company && {
          company: paramsArray?.filters?.company?.value,
        }),
        ...(companyId && {
          company: companyId,
        }),
      };

      const response: any = await http.get(`/positions/template`, {
        signal,
        params,
      });

      const values = getState().positions.positionTemplatesList?.values;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const createPositionByTemplate = createAsyncThunk(
  "positions/createPositionByTemplate",
  async (
    { id, activate }: { id: string; activate: boolean },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.post(`/positions/template/${id}`, {
        activate: activate,
      });
      dispatch(
        setToast({
          message: "Successfully created a position.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const deletePositionTemplate = createAsyncThunk<
  any,
  { id: string },
  {
    state: RootState;
  }
>(
  "positions/deletePositionTemplate",
  async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await http.delete(`/positions/template/${id}`);
      dispatch(
        setToast({
          message: "Successfully deleted a template.",
          type: "success",
        }),
      );
      return getState().positions?.positionTemplatesList?.values?.data?.filter(
        (template: { _id: string }) => template._id !== id,
      );
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const createPositionTemplate = createAsyncThunk(
  "positions/createPositionTemplate",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post("/positions/null/template/save", data);
      dispatch(
        setToast({
          message: "Successfully created a position template.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);
