import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const CompaniesWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/CompaniesWrapper/CompaniesWrapper"),
    ),
);

const CompaniesList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CompaniesList/CompaniesList"),
    ),
);

const CompanyWizard: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/CompanyWizard/CompanyWizard"),
    ),
);

const companiesRoute: routeType[] = [
  {
    path: "/companies",
    element: <CompaniesWrapper />,
    permissions: "GET_COMPANY",
    meta: {
      layout: "app",
      title: "Companies",
      type: "page",
      private: true,
    },
    children: [
      {
        path: "",
        element: <CompaniesList state={""} />,
        meta: {
          type: "component",
          private: true,
        },
      },
    ],
  },
  {
    path: "/create-company",
    element: <CompanyWizard />,
    meta: {
      layout: "app",
      title: "Create company",
      type: "page",
      private: true,
    },
  },
];
export default companiesRoute;
