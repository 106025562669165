import { createAsyncThunk } from "@reduxjs/toolkit";
import { publicHttp } from "common/api/public-http";
import { testCandidateHttp } from "common/api/testCandidate-http";
import { errorRequestType } from "common/types/data.types";
import { setToast } from "redux/alert";
import { setCandidateValue, setIsTestDemo } from "./application.reducer";
import { AppDispatch, RootState } from "redux/store";
import { storeTestCandidateKey } from "common/api/token";
import {
  fetchPositionApplicationPageContent,
  fetchTemplateApplicationPageContent,
} from "./application.services";
import { http } from "common/api/http";
import { formatedAnswerType } from "../application.types";
import { checkIsFinished } from "../common/application.functions";
import { formatModuleFlowTests } from "common/helpers/functions.helpers";

export const getTestSimulationInfo = createAsyncThunk<
  any,
  { id: string; isDemo?: boolean },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getTestSimulationInfo",
  async ({ id, isDemo }, { rejectWithValue, dispatch }) => {
    try {
      const response = await publicHttp.get(`/tests/${id}/simulation/overview`);
      dispatch(setIsTestDemo(isDemo || false));
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  }
);

export const getPositionSimulationInfo = createAsyncThunk<
  any,
  { id: string; isDemo?: boolean; positionTemplate?: boolean },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getPositionSimulationInfo",
  async ({ id, positionTemplate }, { rejectWithValue }) => {
    try {
      const response = positionTemplate
        ? await fetchTemplateApplicationPageContent(id)
        : await fetchPositionApplicationPageContent(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  }
);

export const updatePositionSimulationInfo = createAsyncThunk(
  "application/updatePositionSimulationInfo",
  async (
    {
      id,
      positionBanner,
      recruitingProcessDescription,
      offeringDescription,
      lookingForDescription,
      publicNumberOfApplicants,
      salary,
      benefits,
      jobSummary,
      keyResponsibilities,
      microDims,
      jobDescription,
      isHiringFlowHidden,
      positionTemplate,
    }: {
      id: string;
      positionBanner?: File | null;
      recruitingProcessDescription?: string;
      offeringDescription?: string;
      lookingForDescription?: string;
      publicNumberOfApplicants?: boolean;
      salary?: any;
      benefits?: string;
      jobSummary?: string;
      keyResponsibilities?: string;
      microDims?: { microDimId: string; weight: number }[];
      jobDescription?: any;
      isHiringFlowHidden?: boolean;
      positionTemplate?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    const data = {
      ...(positionBanner === null && {
        positionBanner: null,
      }),
      recruitingProcessDescription,
      offeringDescription,
      lookingForDescription,
      publicNumberOfApplicants,
      salary,
      benefits,
      jobSummary,
      keyResponsibilities,
      microDims,
      jobDescription,
      isHiringFlowHidden,
    };
    try {
      const response = await http.patch(
        `/positions${positionTemplate ? "/template" : ""}/${id}`,
        {
          data: JSON.stringify(data),
          ...(positionBanner && {
            positionBanner: positionBanner,
          }),
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch(
        setToast({
          message: `Successfully updated the ${positionTemplate ? "template" : "position"}.`,
          type: "success",
        })
      );
      const testSingle = positionTemplate
        ? await fetchTemplateApplicationPageContent(id)
        : await fetchPositionApplicationPageContent(id);
      return testSingle.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  }
);

export const submitApplication = createAsyncThunk<
  { applicant: any; id: string },
  {
    id: string;
    name: string;
    surname: string;
    middleName?: string;
    email: string;
    phoneNumber?: string;
    shouldSendResultToEmail?: boolean;
    acceptedPrivacyPolicy: boolean;
    nativeSpeakingLanguage: string;
    qualifyingQuestionAnswers?: formatedAnswerType[];
    reCaptchaToken?: string;
  },
  {
    dispatch: AppDispatch;
  }
>(
  "application/submitApplication",
  async (
    {
      id,
      name,
      surname,
      middleName,
      email,
      phoneNumber,
      shouldSendResultToEmail,
      acceptedPrivacyPolicy,
      nativeSpeakingLanguage,
      qualifyingQuestionAnswers,
      reCaptchaToken,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = (await publicHttp.post(
        `/application/test/${id}`,
        {
          name,
          surname,
          middleName,
          email,
          phoneNumber,
          shouldSendResultToEmail,
          acceptedPrivacyPolicy,
          nativeSpeakingLanguage,
          qualifyingQuestionAnswers,
        },
        {
          headers: {
            "g-recaptcha-token": `${reCaptchaToken}`,
          },
        }
      )) as { data: { applicant: any; id: string } };

      dispatch(setCandidateValue(response.data.applicant));
      localStorage.setItem("lastApplicationId", id);

      return response.data as { applicant: any; id: string };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const submitPositionApplication = createAsyncThunk<
  {
    applicant: any;
    id: string;
    alreadyApplied?: boolean;
    applicationId?: string;
    nextStepEmailTemplate?: string;
  },
  {
    id: string;
    name: string;
    surname: string;
    middleName?: string;
    email: string;
    CV?: any;
    coverLetter?: File;
    customDocuments?: any;
    coverLetterType?: string;
    phoneNumber?: string;
    emailNotifications: boolean;
    acceptedPrivacyPolicy: boolean;
    qualifyingQuestionAnswers?: formatedAnswerType[];
    source?: string;
    reCaptchaToken?: string;
  },
  {
    dispatch: AppDispatch;
  }
>(
  "application/submitPositionApplication",
  async (
    {
      id,
      name,
      surname,
      middleName,
      email,
      CV,
      coverLetter,
      customDocuments,
      phoneNumber,
      emailNotifications,
      acceptedPrivacyPolicy,
      qualifyingQuestionAnswers,
      source,
      reCaptchaToken,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = {
        name,
        surname,
        middleName,
        email,
        phoneNumber,
        emailNotifications,
        acceptedPrivacyPolicy,
        qualifyingQuestionAnswers,
        ...(source && {
          source,
        }),
      };

      const formData = new FormData();

      // Append additional data to FormData
      formData.append("data", JSON.stringify(data));

      // Append CV if present
      if (CV) {
        formData.append("cv", CV);
      }

      // Append cover letter if present
      if (coverLetter) {
        formData.append("coverLetter", coverLetter);
      }

      // Append each file from documents array to FormData
      customDocuments?.forEach((item: { label: string; file: any }) => {
        if (item.file) {
          formData.append(item.label, item.file);
        }
      });

      const response: any = await publicHttp.post(
        `/application/position/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "g-recaptcha-token": `${reCaptchaToken}`,
          },
        }
      );

      dispatch(setCandidateValue(response.data.applicant));
      // localStorage.setItem("lastApplicationId", id);
      dispatch(
        setToast({
          message: "Application successfully sent.",
          type: "success",
        })
      );
      return response.data as { applicant: any; id: string };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error?.config?.method,
        type: "FORM",
        style: "INFO_BOX",
      });
    }
  }
);

export const getTestApplicant = createAsyncThunk<
  any,
  { id: string },
  {
    rejectValue: errorRequestType;
  }
>("application/getTestApplicant", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await testCandidateHttp.get(
      `/application/${id}/applicant`
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      method: error.config.method,
    });
  }
});

export const getApplication = createAsyncThunk<
  any,
  { id?: string | undefined },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getApplication",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await testCandidateHttp.get(
        id ? `/application/${id}` : `/application`
      );

      if (response.data._id) {
        dispatch(
          getTestApplicant({
            id: response.data._id,
          })
        );
        if (!checkIsFinished(response.data?.hiringFlow?.state)) {
          dispatch(getActiveModule({}));
        }
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);

export const verifyApplication = createAsyncThunk<
  any,
  {
    verificationToken: string;
    applicationId: string;
  },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/verifyApplication",
  async (
    { verificationToken, applicationId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = (await publicHttp.post(
        `/application/${applicationId}/email-verify`,
        {
          verificationToken,
        }
      )) as { data: any };

      if (!response.data.alreadyVerified) {
        dispatch(
          setToast({
            message: "E-mail verification success.",
            type: "success",
          })
        );
      }
      storeTestCandidateKey({ access_token: response.data.access_token });

      dispatch(
        getApplication({
          id: applicationId,
        })
      );
      return response.data;
    } catch (err: any) {
      return (window.location.href = "/expired");
    }
  }
);

export const getActiveModule = createAsyncThunk<
  any,
  {
    id?: string;
  },
  {
    rejectValue: errorRequestType;
  }
>("application/getActiveModule", async ({ id }, { rejectWithValue }) => {
  try {
    const response = await testCandidateHttp.get(
      id ? `/application/step/${id}` : `/application/step`
    );

    const formatResponseTests = formatModuleFlowTests(
      response?.data?.userData?.flow,
      response?.data?.userData?.tests
    );
    return {
      ...response.data,
      moduleTests: formatResponseTests || [],
    };
  } catch (error: any) {
    return rejectWithValue({
      ...error.data,
      method: error.config.method,
    });
  }
});

export const getTestSimulationResults = createAsyncThunk<
  any,
  {
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
    testId: string;
  },
  {
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationResults",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue, getState }
  ) => {
    try {
      const response: any = await publicHttp.get(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`
      );
      var data = response.data;
      const vocabModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "VOCAB_MODEL")
            ?.results?.body?.model_info
        : null;
      const accentModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "ACCENT_MODEL")
            ?.results?.body?.model_info
        : null;
      const fluencyModel: any = data?.results?.raw?.jobs.length
        ? data.results.raw.jobs.find((j: any) => j.jobType === "FLUENCY_MODEL")
            ?.results?.body?.model_info
        : null;
      if (data.results) {
        data = {
          ...data,
          results: {
            ...data.results,
            fluencyModel: fluencyModel,
            vocabModel: vocabModel,
            accentModel: accentModel,
          },
        };
      }
      return data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);

export const getTestSimulationPublicResults = createAsyncThunk<
  any,
  {
    applicationId?: string;
    hiringFlowId?: string;
    stepId?: string;
    testId: string;
  },
  {
    state: RootState;
  }
>(
  "testSimulation/getTestSimulationPublicResults",
  async (
    { applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue }
  ) => {
    try {
      const response = await publicHttp.get(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results/public`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);

export const getApplicationFeedback = createAsyncThunk<any, { id: string }>(
  "application/getFeedback",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await testCandidateHttp.get(
        `/application/${id}/feedback`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);

// endpoints for application survey
export const handleSubmitSurvey = createAsyncThunk<
  any,
  {
    data: {
      additionalComment: string;
      questions: { chosenOptionIndex: number }[];
    };
    stepId?: string;
  }
>(
  "application/submitAssessmentSurvey",
  async ({ data, stepId }, { rejectWithValue }) => {
    try {
      const response = await testCandidateHttp.post(
        `/application/survey${stepId ? `/step/${stepId}` : ""}`,
        data
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);

export const getApplicationSurveyTemplate = createAsyncThunk<
  any,
  { stepId?: string },
  {
    rejectValue: errorRequestType;
  }
>(
  "application/getAssessmentSurvey",
  async ({ stepId }, { rejectWithValue }) => {
    try {
      const response = await testCandidateHttp.get(
        `/application/survey${stepId ? `/step/${stepId}` : ""}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
      });
    }
  }
);
