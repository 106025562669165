import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { ReactComponent as ApplicantsNumberIco } from "assets/icons/avatar-sm-ico.svg";
import HiringFlowSteps from "views/components/HiringFlowSteps/HiringFlowSteps";
import { selectHiringFlowModule } from "../../reducer/position.reducer";
import { createNewPositionModule } from "../../reducer/position.actions";

const HiringFlowModules = ({
  scrollTop,
  containerClassName,
  tracking,
  positionTemplate,
}: {
  scrollTop?: number;
  containerClassName?: string;
  tracking?: boolean;
  positionTemplate?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const positionOverview = useAppSelector(
    (state) => state.position.positionOverview,
  );
  const key = useParams();
  const module = useAppSelector((state) => state.position.module);
  const position = useAppSelector((state) => state.position.positionOverview);
  const positionCreateStep = useAppSelector(
    (state) => state.position.positionCreateStep,
  );

  const moduleLength = useAppSelector(
    (state) =>
      state.position.positionOverview?.value?.hiringFlows[0]
        ?.applicationStepModules?.length,
  );

  function selectModule(i: number) {
    navigate(
      `${key.companyId ? `/${key.companyId}` : ""}/${positionTemplate ? "position-template" : "position"}/${
        positionOverview?.value?._id
      }/${tracking ? "tracking" : "hiring-flow"}/step/${i + 1}`,
    );
  }

  useEffect(() => {
    if (
      Number(key.stepId) &&
      positionOverview.value?.hiringFlows[0].applicationStepModules[
        Number(key.stepId) - 1
      ]
    ) {
      dispatch(
        selectHiringFlowModule(
          positionOverview.value?.hiringFlows[0].applicationStepModules[
            Number(key.stepId) - 1
          ],
        ),
      );
    } else if (Number(key.stepId) === 0) {
      selectModule(0);
    }
  }, [key, positionOverview.value]);
  const createModule = (moduleType: string, index: number) => {
    dispatch(
      createNewPositionModule({
        ...(positionTemplate && {
          positionTemplate: positionTemplate,
        }),
        id: position.value._id,
        hiringFlowId: position.value.hiringFlows[0]._id,
        data: {
          moduleType: moduleType,
          stepIndex: index + 1,
        },
      }),
    )
      .then((res) => {
        navigate(
          `${key.companyId ? `/${key.companyId}` : ""}/${positionTemplate ? "position-template" : "position"}/${
            position.value._id
          }/hiring-flow/step/${index + 2}`,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <HiringFlowSteps
        selected={Number(key.stepId) ? Number(key.stepId) - 1 : null}
        containerClassName={containerClassName}
        steps={positionOverview.value.hiringFlows[0].applicationStepModules.map(
          (step: any, i: number) => {
            return {
              ...step,
              info: (
                <>
                  {positionOverview.value?.state !== "DRAFT" && (
                    <div className="flex justify-start items-center px-3.5 py-[5px]">
                      <ApplicantsNumberIco
                        className={`w-[27px] h-auto ${module.value?._id === step._id ? "[&_path]:fill-main-color" : ""} group-hover:[&_path]:fill-main-color`}
                      />
                      <span
                        className={`mt-[1px] ${
                          module.value?._id === step._id
                            ? "text-main-color"
                            : "text-[#8C8C8C]"
                        } group-hover:text-main-color text-base font-[500] text-[#A3A3A3]`}
                      >
                        {step.numberOfApplicants}
                      </span>
                    </div>
                  )}
                </>
              ),
            };
          },
        )}
        onSelectModule={(module, index) => selectModule(index)}
        onCreateModule={(moduleType, index) => createModule(moduleType, index)}
        loading={positionCreateStep.loading || position.refreshLoading}
        viewOnly={
          !(moduleLength < 11 && positionOverview.value?.state === "DRAFT")
        }
        scrollTop={scrollTop}
      />
    </>
  );
};

export default HiringFlowModules;
