import { useState, type ReactNode, useEffect } from "react";
import { ReactComponent as CloseIco } from "assets/icons/close-ico.svg";
import "react-perfect-scrollbar/dist/css/styles.css";
import ScrollBar from "react-perfect-scrollbar";
import "./modal.style.css";

const Modal = (props: {
  children?: ReactNode;
  close: () => void;
  overflow?: "unset";
  childrenClassName?: string;
  preventAutoScroll?: boolean;
  removePadding?: boolean;
  positionModal?: string;
  enableCloseIco?: boolean;
  containerClassName?: string;
  customRef?: any;
  fullScreen?: boolean;
  styleChildren?: any;
}) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [stateModal, setStateModal] = useState<string>("");

  const handleCloseRightModal = () => {
    setStateModal("close");
  };

  useEffect(() => {
    if (props.positionModal === "RIGHT") {
      setTimeout(() => {
        setStateModal("open");
      }, 50);
    }
  }, []);

  useEffect(() => {
    if (!props.preventAutoScroll) {
      const modalScrollbarContainerBox = document.getElementById(
        "modalScrollbarContainerBox",
      );
      modalScrollbarContainerBox?.scroll({ top: 1 });
    }
  }, [isMouseEnter]);

  useEffect(() => {
    if (stateModal === "close") {
      setTimeout(() => {
        props.close();
      }, 200);
    }
  }, [stateModal]);

  useEffect(() => {
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-screen z-50 bg-modal-black overflow-hidden show-animation ${props.containerClassName ? props.containerClassName : ""}`}
      onMouseEnter={() => {
        setIsMouseEnter((prevMouseState) => !prevMouseState);
      }}
    >
      <div
        ref={props?.customRef ? props.customRef : null}
        className="w-full h-screen"
        onClick={() =>
          props.positionModal === "RIGHT"
            ? handleCloseRightModal()
            : props.close()
        }
      ></div>
      {props.overflow ? (
        <div
          className={`${props?.fullScreen ? "!w-[100%] min-w-[100%] !h-[100%] !min-h-[100%] !rounded-none" : ""}
          ${props.positionModal === "RIGHT" ? `h-full top-0 w-auto ${stateModal === "open" ? "right-modal-open" : stateModal === "close" ? "right-modal-close" : "-right-[100%]"}` : "top-[50%] left-[50%] w-fit translate-y-[-50%] translate-x-[-50%] max-h-[80vh] z2xl:max-h-[90%] max-w-[80%] rounded-2xl zmd:w-[95%] zsm:max-w-[94%] zsm:w-full "}
          fixed z-10 bg-white shadow-lg p-10 overflow-auto zsm:p-3.5 ${
            props.childrenClassName ? props.childrenClassName : ""
          }`}
          style={
            props.overflow
              ? { overflow: "unset", ...props.styleChildren }
              : props?.styleChildren
                ? props.styleChildren
                : undefined
          }
        >
          {props.enableCloseIco ? (
            <div
              className={`cursor-pointer mt-[4px] absolute top-[5px] right-[10px] w-[32px] h-[32px] flex justify-center items-center rounded-lg hover:bg-z-grey-100 group`}
            >
              <CloseIco
                className={
                  "cursor-pointer  transition-all group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500 "
                }
                onClick={() =>
                  props.positionModal === "RIGHT"
                    ? handleCloseRightModal()
                    : props.close()
                }
              />
            </div>
          ) : null}
          {props.children}
        </div>
      ) : props.children ? (
        <ScrollBar
          id="modalScrollbarContainerBox"
          component="div"
          className={`${props?.fullScreen ? "!w-[100%] min-w-[100%] !h-[100%] !min-h-[100%] !rounded-none" : ""}
           ${props.positionModal === "RIGHT" ? `h-full top-0 w-auto ${stateModal === "open" ? "right-modal-open" : stateModal === "close" ? "right-modal-close" : "-right-[100%]"}` : "top-[50%] left-[50%] w-fit translate-y-[-50%] translate-x-[-50%] h-auto max-h-[80vh] z2xl:max-h-[90%] max-w-[80%] rounded-2xl zmd:w-[95%] zsm:max-w-[94%] zsm:w-full"}
          fixed z-10 bg-white shadow-lg ${
            props.removePadding ? "p-0" : "p-10"
          } overflow-auto zsm:p-3.5 ${
            props.childrenClassName ? props.childrenClassName : ""
          }`}
          options={{
            wheelPropagation: true,
          }}
        >
          {props.enableCloseIco ? (
            <div
              className={`cursor-pointer mt-[4px] absolute top-[5px] right-[10px] w-[32px] h-[32px] flex justify-center items-center rounded-lg hover:bg-z-grey-100 group`}
            >
              <CloseIco
                className={
                  "cursor-pointer  transition-all group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500 "
                }
                onClick={() =>
                  props.positionModal === "RIGHT"
                    ? handleCloseRightModal()
                    : props.close()
                }
              />
            </div>
          ) : null}
          {props.children}
        </ScrollBar>
      ) : null}
    </div>
  );
};

export default Modal;
