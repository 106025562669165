import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import { FunctionComponent, useEffect } from "react";

type typeProps = {
  saveEditorToHtml: (value: string) => void;
};
export const HtmlSerializerPlugin: FunctionComponent<typeProps> = (props) => {
  const { saveEditorToHtml } = props;
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        editorState.read(() => {
          const htmlString = $generateHtmlFromNodes(editor, null);
          saveEditorToHtml(htmlString);
        });
      },
    );
    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  return <></>;
};
