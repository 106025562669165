import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ReactComponent as SearchIco } from "assets/icons/search-ico.svg";
import { ReactComponent as ResetFilters } from "assets/icons/close-ico.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxTypes";
import { useLocation } from "react-router";
import { setGlobalFilters } from "../../../../redux/globalFilters";
import { useDebounce } from "../../../../common/hooks/useDebounce";

type typeProps = {
  placeholder?: string;
  modalPath?: boolean;
  customPath?: string;
};
const SearchFilterInput: FunctionComponent<typeProps> = (props) => {
  const { placeholder, modalPath, customPath } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const calculatePath = useMemo(() => {
    return customPath
      ? customPath
      : modalPath
        ? `${location.pathname}?modal=true`
        : location.pathname;
  }, [location.pathname, customPath, modalPath]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const globalFilters = useAppSelector(
    (state) => state.globalFilters[calculatePath]
  );
  const searchDebouncer = useDebounce(search, 800);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!globalFilters?.search) {
      setSearch(undefined);
    } else {
      setSearch(globalFilters?.search);
    }
  }, [calculatePath]);

  // Function to check for two or more consecutive spaces between words
  const hasMultipleSpacesBetweenWords = (value: string): boolean => {
    return /\s{2,}/.test(value);
  };

  const searchFunction = (value: string) => {
    if (!hasMultipleSpacesBetweenWords(value)) {
      setSearch(value);
    }
  };

  useEffect(() => {
    if (searchDebouncer !== undefined) {
      dispatch(
        setGlobalFilters({
          location: calculatePath,
          search: searchDebouncer,
          page: 1,
        })
      );
    }
  }, [searchDebouncer]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={
        "flex pl-[10px] rounded-[47px] bg-z-grey-100 overflow-hidden group"
      }
    >
      <SearchIco
        className={`mt-[2px] mr-[2px] !min-w-[27px] group-hover:[&_path]:stroke-main-color ${isFocused ? "[&_path]:stroke-main-color" : ""}`}
      />
      <input
        className={`w-full border-y border-transparent focus:outline-none focus:border-transparent focus:placeholder:text-transparent bg-z-grey-100 text-sm`}
        type="text"
        value={search || ""}
        onChange={(e) => searchFunction(e.target.value)}
        placeholder={placeholder || "Search"}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {search?.length ? (
        <div
          className={
            "bg-z-grey-100 rounded-r-full flex justify-center items-center mr-[1px] mt-[2px] ml-[1px]"
          }
        >
          <div
            className={`hover:bg-[#DDDDDD] rounded-full p-1 h-[25px] w-[25px] flex justify-center items-center zh-dropdown-filters cursor-pointer`}
          >
            <ResetFilters
              onClick={(e) => setSearch("")}
              className={
                " [&_path]:fill-main-color hover:bg-[#DDDDDD] rounded-full w-full h-full"
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchFilterInput;
