const NotificationBadge = ({
  number,
  className,
  type,
}: {
  number?: number;
  className?: string;
  type?: "number" | "dot";
}) => {
  return type && type === "dot" ? (
    <div className="min-w-[9px] w-[9px] h-[9px] bg-z-red-light rounded-full mb-[1px]"></div>
  ) : (
    <div
      className={`min-w-[20px] px-1 py-[3px] text-xs text-white bg-z-red-light flex rounded-lg items-center justify-center ${className || ""}`}
    >
      {number || ""}
    </div>
  );
};

export default NotificationBadge;
