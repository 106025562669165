import { ReactNode } from "react";
import { ReactComponent as InfoIco } from "assets/icons/info-ico.svg";

interface propTypes {
  children: ReactNode;
  CustomIcon?: string;
  hideIcon?: boolean;
  className?: string;
  type?: "info" | "warning" | "error" | "success" | "";
}

function renderIconColor(type: string) {
  switch (type) {
    case "info":
      return "[&_path]:fill-dark-blue";
    case "warning":
      return "[&_path]:fill-dark-info";
    case "error":
      return "[&_path]:fill-invalid-red";
    case "success":
      return "[&_path]:fill-green";
    default:
      return "[&_path]:fill-dark-info";
  }
}

function renderTextColor(type: string) {
  switch (type) {
    case "info":
      return "text-dark-blue";
    case "warning":
      return "text-dark-info";
    case "error":
      return "text-invalid-red";
    case "success":
      return "text-green";
    default:
      return "text-dark-info";
  }
}

function renderBgColor(type: string) {
  switch (type) {
    case "info":
      return "bg-light-blue";
    case "warning":
      return "bg-light-info";
    case "error":
      return "bg-light-red";
    case "success":
      return "bg-light-main-green";
    default:
      return "bg-light-info";
  }
}

const CustomNote = ({
  children,
  type = "",
  hideIcon,
  className,
  CustomIcon,
}: propTypes) => {
  return (
    <div
      className={`${renderTextColor(type)} ${renderBgColor(
        type
      )} p-[15px] text-sm font-light rounded-xl ${className || ""}`}
    >
      <div className={`flex items-center gap-2`}>
        {!hideIcon ? (
          <div>
            <InfoIco className={`h-[20px] ${renderIconColor(type)}`} />
          </div>
        ) : (
          ""
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};
export default CustomNote;
