import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PositionsWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/PositionsWrapper/PositionsWrapper"),
    ),
);
const PositionsTemplateWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import(
          "../wrappers/PositionsTemplatesWrapper/PositionsTemplateWrapper"
        ),
    ),
);
const PositionsList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionsList/PositionsList"),
    ),
);
const PositionTemplatesList: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () =>
        await import("../pages/PositionTemplatesList/PositionTemplatesList"),
    ),
);
const PositionWizard: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/PositionWizard/PositionWizard"),
    ),
);

const positionsRoutes: routeType[] = [
  {
    path: "/positions",
    element: <PositionsWrapper />,
    permissions: "GET_POSITIONS",
    meta: {
      layout: "app",
      title: "Positions",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <PositionsList />,
        meta: {
          type: "component",
          company: true,
          private: true,
        },
      },
    ],
  },
  {
    path: "/positions/templates",
    element: <PositionsTemplateWrapper />,
    permissions: "VIEW_POSITION_TEMPLATES",
    meta: {
      layout: "app",
      title: "Position Templates",
      type: "page",
      private: true,
      company: true,
    },
    children: [
      {
        path: "",
        element: <PositionTemplatesList />,
        meta: {
          type: "component",
          company: true,
          private: true,
        },
      },
    ],
  },
  {
    path: "/create-position",
    element: <PositionWizard />,
    permissions: "CREATE_POSITION",
    meta: {
      layout: "app",
      title: "Create Position",
      type: "page",
      company: true,
      private: true,
      roles: ["zenhire"],
    },
  },
];

export default positionsRoutes;
