import { useState, useEffect, useMemo, ReactNode } from "react";
import { ReactComponent as CheckIco } from "assets/icons/check-ico-green.svg";
import { ReactComponent as MinusIco } from "assets/icons/not-check-box-ico.svg";
interface propsTypes {
  text: string | ReactNode;
  isChecked?: boolean;
  onChange: (e: boolean) => void;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
  link?: boolean;
  linkPath?: string;
  linkNode?: ReactNode;
  notAllChecked?: boolean;
  disableCheckFunction?: boolean;
  textStyle?: string;
  containerClassName?: string;
  onlyCheckOn?: boolean;
}

const CheckBox = (props: propsTypes) => {
  const [checked, setChecked] = useState(false);
  const [boxStyle, setBoxStyle] = useState("");

  const checkedHnadle = (e: any) => {
    e.stopPropagation();
    if (props.onlyCheckOn) {
      props.onChange(!checked);
    } else {
      if (!props.disableCheckFunction) {
        setChecked((prevChecked) => !prevChecked);
      }
      props.onChange(!checked);
    }
  };

  useEffect(() => {
    if (checked) {
      if (props.disabled) {
        setBoxStyle("text-z-grey-600 cursor-not-allowed");
      } else {
        setBoxStyle("text-main-color");
      }
    } else {
      if (props.disabled) {
        setBoxStyle("text-z-grey-600 cursor-not-allowed");
      } else {
        setBoxStyle("text-black");
      }
    }
  }, [checked, props.disabled, props.isChecked]);

  useMemo(() => {
    setChecked(props?.isChecked ? props.isChecked : false);
  }, [props.isChecked]);

  return (
    <div className="flex items-center gap-2 justify-center">
      <div
        className={`relative ${props.containerClassName ? props.containerClassName : ""}`}
        onClick={(e) => !props.disabled && checkedHnadle(e)}
      >
        <div
          className={`w-5 h-5 rounded 
          ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}
          ${props.disabled ? "grayscale bg-z-grey-450" : props.invalid ? "border-2 border-invalid-red bg-invalid-dimmed-red" : checked || props.notAllChecked ? "bg-main-color" : "bg-z-grey-450"}
          ${props.className ? props.className : ""} `}
        >
          {checked || props.notAllChecked ? (
            <div className={"w-5 h-5 flex justify-center items-center"}>
              {checked ? (
                <CheckIco className={"w-3.5 h-3.5 [&_path]:fill-white"} />
              ) : (
                <MinusIco className={"[&_rect]:fill-main-color w-full"} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {props.linkNode || props.text ? (
        typeof props.text !== "string" ? (
          <>{props.text}</>
        ) : (
          <span
            className={`w-fit ${boxStyle} ${
              props.invalid ? "text-invalid-red" : "text-black"
            } select-none ${props.textStyle}`}
          >
            {props.linkNode ? props.linkNode : `${props.text}`}
          </span>
        )
      ) : null}
    </div>
  );
};

export default CheckBox;
