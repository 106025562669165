import { Fragment, useCallback, useState } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "../../../../../../redux/reduxTypes";
import { ReactComponent as PercentIco } from "assets/icons/percent-ico.svg";
import CreatePortal from "../../../../../components/CreatePortal/CreatePortal";
import CandidateListModal from "../../CandidateListModal/CandidateListModal";

const CandidateListAdvancedFilters = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname]
  );
  const modalHandle = useCallback(() => {
    setShowModal((prevModal) => !prevModal);
  }, []);

  const formatScoreFilter = () => {
    const scoreFilter = globalFilters?.filters?.score;
    const scoreValue = scoreFilter?.score;
    switch (scoreFilter?.type) {
      case "top":
        return `Top ${scoreValue} Applicants`;
      case "topPercentage":
        return `Top ${scoreValue}% Applicants`;
      case "threshold":
        return `All applicants over ${scoreValue}%`;
    }
  };

  return (
    <Fragment>
      <div
        onClick={modalHandle}
        className={`flex justify-center items-center gap-2 cursor-pointer rounded-[10px] border  border-z-grey-400 h-[35px] hover:bg-z-grey-100
         ${globalFilters?.filters?.score ? "text-main-color" : ""}
         `}
      >
        <div>
          {globalFilters?.filters?.score ? (
            <span className={"block text-sm"}>{formatScoreFilter()}</span>
          ) : (
            <div
              className={
                "flex justify-center items-center gap-2 font-[22px] group-hover:text-main-color text-z-grey-450"
              }
            >
              <PercentIco
                className={
                  "text-z-grey-450 block group-hover:[&_path]:fill-main-color"
                }
              />
              <span className={"text-z-grey-550 text-sm"}>Filter by score</span>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <CreatePortal>
          <CandidateListModal
            title={"Filter by score"}
            handleCloseModal={modalHandle}
          />
        </CreatePortal>
      )}
    </Fragment>
  );
};

export default CandidateListAdvancedFilters;
