import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "../Button/Button";
import { ReactComponent as PlusIco } from "assets/icons/plus-ico.svg";
import SearchFilterInput from "./components/SearchFilterInput";
import ScrollBar from "react-perfect-scrollbar";
import { ReactComponent as SideBarIco } from "assets/icons/sidebar-ico.svg";
import { ReactComponent as DropdownDots } from "assets/icons/dropdown-dots-icon.svg";
import "./filters-side-bar.style.css";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { useNavigate } from "react-router";

type typeProps = {
  sidebar: {
    sidebarClassName?: string;
    sidebarMergeClassName?: string;
    closedDivClassName?: string;
    children: ReactNode;
    hideSidebar?: boolean;
    dropdownOptions?: {
      hide?: boolean;
      options: any;
    };
  };
  customHeaderContent?: ReactNode;
  contentClassName?: string;
  children: ReactNode;
  title: {
    text: string;
    Icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string | undefined }
    >;
    children?: ReactNode;
    iconClassName?: string;
  };
  createButton?: {
    text: string;
    hide?: boolean;
    link?: string;
    handleFunction?: (value: boolean) => void;
  };
  searchBar?: {
    placeholder?: string;
  };
  containerClassName?: string;
  modalPath?: boolean;
  customPath?: string;
};
const FiltersSideBar: FunctionComponent<typeProps> = (props) => {
  const {
    sidebar,
    customHeaderContent,
    contentClassName,
    children,
    title,
    createButton,
    searchBar,
    containerClassName,
    modalPath,
    customPath,
  } = props;

  const navigate = useNavigate();
  const [scrollBarHeight, setScrollBarHeight] = useState<number | undefined>(
    undefined,
  );
  const [showHideIcon, setShowHideIcon] = useState<boolean>(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sidebarRef.current && contentRef.current) {
      const sidebarHeight = sidebarRef.current.clientHeight;
      const contentHeight = contentRef.current.clientHeight;
      setScrollBarHeight(sidebarHeight - contentHeight);
    }
  }, [sidebarRef.current, contentRef.current]);

  const [isVisible, setIsVisible] = useState(true);
  const toggleSidebar = () => {
    setIsVisible((prevState) => !prevState);
  };

  const [divWidth, setDivWidth] = useState<number>(0);

  useEffect(() => {
    if (sidebarRef.current) {
      setDivWidth(sidebarRef.current.clientWidth);
    }

    // Optional: Add an event listener for window resize to update width dynamically
    const handleResize = () => {
      if (sidebarRef.current) {
        setDivWidth(sidebarRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`relative flex-1 overflow-hidden flex mx-auto w-full ${containerClassName || ""}`}
    >
      {!sidebar?.hideSidebar ? (
        <div
          onMouseEnter={() => setShowHideIcon(true)}
          onMouseLeave={() => setShowHideIcon(false)}
          style={{
            marginLeft: !isVisible ? `-${divWidth}px` : "",
          }}
          ref={sidebarRef}
          className={`${
            sidebar?.sidebarClassName
              ? sidebar?.sidebarClassName
              : `transition-all duration-300 relative min-w-[257px] max-w-[257px] zlg:min-w-[230px] zlg:max-w-[230px] flex flex-col h-auto pt-[20px] border-r border-z-grey-200 ${sidebar?.sidebarMergeClassName || ""}`
          }`}
        >
          <div
            className={"px-[15px] zlg:px-[10px] flex flex-col gap-5"}
            ref={contentRef}
          >
            <div
              className={
                "flex items-center justify-between gap-2.5 cursor-default"
              }
            >
              <div
                className={`flex gap-2.5 items-center h-[35px]`}
                style={{
                  width:
                    sidebar.dropdownOptions && !sidebar.dropdownOptions.hide
                      ? "85%"
                      : !showHideIcon
                        ? "100%"
                        : "",
                  maxWidth:
                    sidebar.dropdownOptions &&
                    !sidebar.dropdownOptions.hide &&
                    showHideIcon
                      ? "165px"
                      : showHideIcon
                        ? "196px"
                        : "",
                }}
              >
                {title.Icon ? (
                  <title.Icon
                    className={`[&_path]:fill-main-color min-w-fit ${title?.iconClassName || ""}`}
                  />
                ) : null}
                {title.children ? title.children : null}
                <span className={"text-xl font-[600] text-z-grey-650 truncate"}>
                  {title.text}
                </span>
              </div>
              <div className={"mt-[2px] flex items-center justify-center"}>
                {sidebar.dropdownOptions && !sidebar.dropdownOptions.hide ? (
                  <CustomDropdown
                    IconDropdown={DropdownDots}
                    functionOption={sidebar.dropdownOptions.options}
                  />
                ) : null}
                {showHideIcon ? (
                  <div
                    onClick={toggleSidebar}
                    className={`w-[32px] h-[32px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-z-grey-100 group mx-auto`}
                  >
                    <SideBarIco
                      className={
                        "cursor-pointer group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500"
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {customHeaderContent && customHeaderContent}
            {createButton && !createButton.hide ? (
              <Button
                type="Primary"
                text={createButton.text}
                FunctionIcon={PlusIco}
                className="!rounded-[5px] !w-full !px-[7px] !py-[5.5px] !text-sm"
                onClick={() =>
                  createButton?.handleFunction
                    ? createButton?.handleFunction(true)
                    : createButton?.link
                      ? navigate(createButton?.link)
                      : null
                }
              />
            ) : null}
            {searchBar ? (
              <SearchFilterInput
                placeholder={searchBar?.placeholder}
                modalPath={modalPath}
                customPath={customPath}
              />
            ) : null}
            <hr className="border-1 border-z-grey-200" />
          </div>
          <ScrollBar
            component="div"
            options={{
              wheelPropagation: true,
            }}
            className={
              "px-[15px] zlg:px-[10px] flex flex-col gap-5 pb-[20px] pt-[10px]"
            }
            style={{
              height: `${scrollBarHeight}px`,
            }}
          >
            {sidebar?.children}
          </ScrollBar>
        </div>
      ) : null}
      {!isVisible ? (
        <div
          className={`transition-all duration-300 relative w-[40px] flex flex-col h-auto pt-[20px] border-r border-z-grey-200 ${sidebar?.closedDivClassName || ""}`}
        >
          <div
            onClick={toggleSidebar}
            className={`w-[32px] h-[32px] flex justify-center items-center rounded-lg cursor-pointer hover:bg-z-grey-100 group mx-auto`}
          >
            <SideBarIco
              className={
                "cursor-pointer group-hover:[&_path]:fill-main-color [&_path]:fill-z-grey-500"
              }
            />
          </div>
        </div>
      ) : null}
      <div
        id="sideBarPageDiv"
        className={`w-full flex flex-col overflow-auto show-animation ${
          contentClassName ? contentClassName : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default FiltersSideBar;
