import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "common/api/http";
import { AppDispatch, RootState } from "redux/store";
import { errorRequestType } from "../../../../common/types/data.types";
import { isObjEmpty } from "common/utility/Utils";
import { setToast } from "../../../../redux/alert";
import {
  updateApplicationHiringFlowLiveInterview,
  updateApplicationHiringFlowStatus,
  updateApplicationResults,
  updateApplicationStepResults,
  updateApplicationStepTestsStateCompleted,
  updateLiveInterviewModuleMicrodimensions,
  updateLiveInterviewModuleValue,
  updateModuleTestResults,
  updateTestResults,
} from "./applicant.reducer";

export const getApplicantProfile = createAsyncThunk<any, { id: string }>(
  "applicant/getApplicantProfile",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(`/applicant/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

type multiSelectType = {
  _id: string;
};
export const getApplicantApplications = createAsyncThunk<
  any,
  {
    id: string;
    page: number;
    paramsArray?: any;
  },
  { state: RootState }
>(
  "applicant/getApplicantApplications",
  async ({ id, page, paramsArray }, { getState, rejectWithValue }) => {
    const params = {
      limit: 15,
      page: page ? page : 1,
      ...(paramsArray?.search && {
        search: paramsArray?.search,
      }),
      ...(paramsArray?.filters?.dateFrom && {
        dateFrom: paramsArray?.filters?.dateFrom,
      }),
      ...(paramsArray?.filters?.dateTo && {
        dateTo: paramsArray?.filters?.dateTo,
      }),
      ...(paramsArray?.filters?.sort && {
        sort: `${paramsArray?.filters?.direction?.value === "desc" ? "-" : ""}${
          paramsArray?.filters?.sort.value
        }`,
      }),
      ...(paramsArray?.filters?.test && {
        testId: paramsArray?.filters?.test.value,
      }),
      ...(paramsArray?.filters?.position && {
        positionId: paramsArray?.filters?.position.value,
      }),
      ...(paramsArray?.filters?.status && {
        state: paramsArray?.filters?.status.value,
      }),
      ...(paramsArray?.filters?.microdimensions && {
        microdimensions: `${paramsArray?.filters?.microdimensions.map(
          (microdimension: multiSelectType) => microdimension._id,
        )}`,
      }),
    };

    try {
      const response = await http.get(`/applicant/${id}/applications`, {
        params,
      });

      const values = getState().applicant?.applications?.value;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const getApplicantApplication = createAsyncThunk<
  any,
  { id: string; applicationId: string },
  {
    rejectValue: errorRequestType;
  }
>(
  "applicant/getApplicantApplication",
  async ({ id, applicationId }, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await http.get(
        `/applicant/${id}/application/${applicationId}`,
      );
      const application = response.data;

      const module =
        application.test && !isObjEmpty(application?.testFull)
          ? application.testFull?.template?.enum === "QUALIFYING_QUESTIONS"
            ? application.hiringFlow?.applicationStepModules[0]
            : application.hiringFlow?.applicationStepModules[1]
          : null;

      if (module) {
        dispatch(
          getApplicantApplicationModule({
            id: id,
            applicationId: applicationId,
            stepId: module.moduleType === "auth" ? "1" : "2",
          }),
        );
      }
      return application;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const getApplicantApplicationModule = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    stepId: string;
  },
  {}
>(
  "applicant/getApplicantApplicationModule",
  async ({ id, applicationId, stepId }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/${stepId}`,
      );
      return { ...response.data, index: stepId };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "STEP_PAGE",
      });
    }
  },
);

export const getApplicantTestResults = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {}
>(
  "applicant/getApplicantTestResults",
  async (
    { id, applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue },
  ) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "DETAILED_REPORT_MODAL",
      });
    }
  },
);

export const refreshApplicantTestResults = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  {}
>(
  "applicant/refreshApplicantTestResults",
  async (
    { id, applicationId, hiringFlowId, stepId, testId },
    { rejectWithValue },
  ) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/results`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const handleSubmitMicrodimensionScore = createAsyncThunk<
  any,
  {
    applicantId: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    questionId: string;
    microdimensions: { microdimensionId: string; score: number }[];
  },
  {}
>(
  "applicant/handleSubmitMicrodimensionScore",
  async (
    {
      applicantId,
      applicationId,
      hiringFlowId,
      stepId,
      testId,
      questionId,
      microdimensions,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response: any = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/score`,
        {
          microdimensions,
          questionId,
        },
      );
      dispatch(
        refreshApplicantTestResults({
          id: applicantId,
          applicationId: applicationId,
          hiringFlowId: hiringFlowId,
          stepId: stepId,
          testId: testId,
        }),
      );
      dispatch(updateTestResults(response.data));
      dispatch(updateModuleTestResults({ ...response.data, id: testId }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const handleSubmitFinishScoring = createAsyncThunk<
  any,
  {
    applicantId: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
  },
  { dispatch: AppDispatch }
>(
  "applicant/handleSubmitFinishScoring",
  async (
    { applicantId, applicationId, hiringFlowId, stepId, testId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response: any = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/test/${testId}/finish-scoring`,
      );
      dispatch(updateApplicationResults(response?.data?.applicationResults));
      dispatch(
        updateApplicationStepResults({
          stepResults: response?.data?.stepResults,
          moduleState: response?.data?.moduleState,
        }),
      );
      dispatch(updateApplicationStepTestsStateCompleted(testId));
      dispatch(
        updateApplicationHiringFlowStatus({
          id: stepId,
          moduleState: response?.data?.moduleState,
          hiringFlowSate: response?.data?.hiringFlowSate,
        }),
      );

      dispatch(
        setToast({
          message: "Successfully finished scoring.",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const refreshApplicantApplicationModule = createAsyncThunk<
  any,
  {
    id: string;
    applicationId: string;
    stepId: number;
  },
  {}
>(
  "applicant/refreshApplicantApplicationModule",
  async ({ id, applicationId, stepId }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `/applicant/${id}/application/${applicationId}/${stepId}`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  },
);

export const handleLiveInterviewScoring = createAsyncThunk<
  any,
  {
    data: { microdimensionId: string; score: number }[];
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    applicantId: string;
    initialStep: number;
  },
  {}
>(
  "applicant/handleLiveInterviewScoring",
  async (
    { data, applicationId, hiringFlowId, stepId, applicantId, initialStep },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response: any = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/score`,
        data,
      );
      dispatch(
        updateLiveInterviewModuleValue({
          flag: "canFinishScoring",
          value: response?.data?.canFinishScoring,
        }),
      );
      dispatch(
        updateLiveInterviewModuleMicrodimensions({
          microdimensions: response?.data?.microdimensions,
        }),
      );

      dispatch(
        setToast({
          message: "Successfully updated score.",
          type: "success",
        }),
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const handleFinishScoringLiveInterview = createAsyncThunk<
  any,
  {
    applicantId: string;
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    initialStep: number;
  },
  { dispatch: AppDispatch }
>(
  "applicant/handleFinishScoringLiveInterview",
  async (
    { applicantId, applicationId, hiringFlowId, stepId, initialStep },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response: any = await http.post(
        `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/live-interview/finish-scoring`,
      );

      dispatch(
        updateApplicationStepResults({
          stepResults: response?.data?.stepResults,
          moduleState: response?.data?.moduleState,
          scoredAt: response?.data?.scoredAt,
        }),
      );
      dispatch(
        updateApplicationHiringFlowStatus({
          id: stepId,
          moduleState: response?.data?.moduleState,
          hiringFlowSate: response?.data?.hiringFlowSate,
        }),
      );
      dispatch(
        updateApplicationHiringFlowLiveInterview({
          id: stepId,
          moduleState: response?.data?.moduleState,
          hiringFlowSate: response?.data?.hiringFlowState,
          finalScore: response?.data?.stepResults?.finalScore,
        }),
      );

      dispatch(
        setToast({
          message: "Successfully finished scoring.",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const recalculateResults = createAsyncThunk<
  any,
  {
    applicationId: string;
    saveResults: boolean;
  },
  {}
>(
  "applicant/recalculateResults",
  async ({ applicationId, saveResults }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `application/${applicationId}/recalculate?save=${saveResults}`,
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const recalculatePreinterviewResults = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
    testId: string;
    manualScoringRecalculate?: boolean;
  },
  {
    state: RootState;
  }
>(
  "applicant/recalculatePreinterviewResults",
  async (
    { applicationId, hiringFlowId, stepId, testId, manualScoringRecalculate },
    { getState, rejectWithValue },
  ) => {
    try {
      const response = await http.post(
        `application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/preinterview-test/${testId}/recalculate`,
      );

      if (manualScoringRecalculate) {
        const values = getState().applicant?.testResults?.value;
        return {
          type: "manualScoring",
          data: {
            ...values,
            aiProcessingStatus: "PROCESSING",
          },
        };
      } else {
        const values = getState().applicant.module.value?.userData?.tests;
        return values?.map((test: any) =>
          test._id === testId
            ? {
                ...test,
                aiProcessingStatus: "PROCESSING",
              }
            : test,
        );
      }
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

// APPLICATION COMMENTS

export const createApplicationComment = createAsyncThunk<
  any,
  {
    comment: string;
    applicationId: string;
  },
  {}
>(
  "applicant/storeApplicationComments",
  async ({ comment, applicationId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.post(`application/${applicationId}/comment`, {
        comment,
      });
      dispatch(
        setToast({
          message: "Successfully created comment.",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const getApplicantApplicationComments = createAsyncThunk<
  any,
  { applicationId: string }
>(
  "applicant/getApplicationComments",
  async ({ applicationId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(`application/${applicationId}/comments`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
        position: "COMMENTS_SIDEBAR",
      });
    }
  },
);

export const deleteApplicationComment = createAsyncThunk<
  any,
  {
    commentId: string;
    applicationId: string;
  },
  {}
>(
  "applicant/deleteApplicationComments",
  async ({ commentId, applicationId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await http.delete(
        `application/${applicationId}/comment/${commentId}`,
      );
      dispatch(
        setToast({
          message: "Successfully deleted comment.",
          type: "success",
        }),
      );

      return { idComment: commentId };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

export const updateApplicationComment = createAsyncThunk<
  any,
  {
    comment: string;
    commentId: string;
    applicationId: string;
  },
  {}
>(
  "applicant/editApplicationComments",
  async (
    { comment, applicationId, commentId },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await http.put(
        `application/${applicationId}/comment/${commentId}`,
        {
          comment,
        },
      );
      dispatch(
        setToast({
          message: "Successfully updated comment.",
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);

// APPLICATION ACTIONS

export const applicantApplicationActions = createAsyncThunk<
  any,
  {
    positionId: string;
    stepId: string;
    data: string[];
    type: string;
  },
  {
    state: RootState;
  }
>(
  "position/applicantApplicationActions",
  async (
    { positionId, stepId, data, type },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response: { data: { moduleState: string; _id: string }[] } =
        await http.put(
          `/positions/${positionId}/step/${Number(stepId)}/${type}`,
          {
            applicationIds: data,
          },
        );

      dispatch(
        setToast({
          message: `Successfully ${type !== "hire" ? `${type}ed` : "hired"}`,
          type: "success",
        }),
      );

      return response;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

export const retryApplicationAssessment = createAsyncThunk<
  any,
  {
    applicationId: string;
    hiringFlowId: string;
    stepId: string;
  },
  {
    state: RootState;
  }
>(
  "position/retryApplicationAssessment",
  async (
    { applicationId, stepId, hiringFlowId },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response: { data: { moduleState: string; _id: string }[] } =
        await http.get(
          `/application/${applicationId}/hiring-flows/${hiringFlowId}/step/${stepId}/restart`,
        );

      dispatch(
        setToast({
          message: `Successfully retried an applicant.`,
          type: "success",
        }),
      );

      return response?.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "MODAL",
      });
    }
  },
);

// APPLICATION FOLLOW
export const followUnfollowApplication = createAsyncThunk<
  any,
  {
    applicationId: string;
    typeAction: "follow" | "unfollow" | "transfer";
    newFollowerId?: string;
  }
>(
  "applicant/followApplication",
  async (
    { applicationId, typeAction, newFollowerId },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await http.post(
        `application/${applicationId}/${typeAction === "transfer" ? "unfollow" : typeAction}`,
        {
          ...(typeAction === "transfer" && {
            newFollowerId: newFollowerId,
          }),
        },
      );

      dispatch(
        setToast({
          message: `Successfully ${typeAction}ed candidate.`,
          type: "success",
        }),
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  },
);
