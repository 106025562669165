import {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { fetchAllCompanyUsers } from "../../../../../../common/helpers/actions.helpers";
import { setFiltersOptions } from "../../../../../../redux/globalFiltersOptions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../redux/reduxTypes";
import { useParams } from "react-router";
import Avatar from "../../../../../components/Avatar/Avatar";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import CustomDropdown from "../../../../../components/CustomDropdown/CustomDropdown";
import CheckBox from "../../../../../components/CheckBox/CheckBox";
import { ReactComponent as SearchIco } from "assets/icons/search-ico.svg";
import ScrollBar from "react-perfect-scrollbar";
import { ReactComponent as InfoIco } from "assets/icons/info-ico.svg";
import CustomNote from "views/components/CustomNote/CustomNote";
import Spinner from "views/components/spinners/Spinner";

type typeProps = {
  selectedUsers: any;
  setSelectedUsers: (value: any) => void;
  type?: "single" | "multiple";
  spinnerClassName?: string;
  hideInfo?: boolean;
};

type typeMember = {
  name: string;
  surname: string;
  middleName: string;
  avatar: string;
  _id: string;
  background?: string;
};

const ApplicantTransferFollow: FunctionComponent<typeProps> = (props) => {
  const dispatch = useAppDispatch();
  const key = useParams();
  const { selectedUsers, setSelectedUsers, type, spinnerClassName, hideInfo } =
    props;
  const [loading, setLoading] = useState(false);
  const filtersOptions = useAppSelector(
    (state) => state.globalFiltersOptions.filtersOptions,
  );

  const [isSingleSelect, setIsSingleSelect] = useState<boolean>(true); // Or false for multiple select
  const [usersListBox, setUsersListBox] = useState<any>([]);
  const [usersListForSearch, setUsersListForSearch] = useState<any>([]);

  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (type === "multiple") {
      setIsSingleSelect(false);
    }
  }, [type]);

  useEffect(() => {
    if (!filtersOptions?.companyUsers?.length) {
      setLoading(true);
      fetchAllCompanyUsers(key.companyId ? key.companyId : "").then((res) => {
        dispatch(
          setFiltersOptions({
            flag: "companyUsers",
            data: res?.data?.data,
          }),
        );
        setLoading(false);
      });
    }
  }, [filtersOptions]);

  // USERS LIST EXCEPT USERS FROM SEARCH BOX
  const usersExceptSearch = useMemo(() => {
    return (
      filtersOptions?.companyUsers?.slice(0, 5).map((user: any) => user) || []
    );
  }, [filtersOptions?.companyUsers]);

  // INITIAL USERS FOR SEARCH BOX
  useEffect(() => {
    setUsersListForSearch(
      filtersOptions?.companyUsers?.map((user: any) => user)?.slice(5),
    );
  }, [filtersOptions?.companyUsers]);

  // USERS FOR SEARCH BOX
  useEffect(() => {
    if (usersListForSearch) {
      setUsersListBox(usersListForSearch);
    }
  }, [usersListForSearch]);

  const handleSelectUser = (userId: string) => {
    if (isSingleSelect) {
      // For single select, toggle selection
      setSelectedUsers(
        selectedUsers.includes(userId)
          ? [] // Deselect user if already selected
          : [userId], // Select user, replace previous selection
      );
    } else {
      // For multiple select, toggle selection
      setSelectedUsers(
        selectedUsers.includes(userId)
          ? selectedUsers.filter((id: string) => id !== userId)
          : selectedUsers === "notFollowed"
            ? [userId]
            : [...selectedUsers, userId],
      );
    }
  };

  const searchFunction = (value: string) => {
    setSearch(value);
  };

  // Function to remove all spaces
  const removeSpaces = (str: string): string => {
    return str.replace(/\s+/g, "");
  };

  useEffect(() => {
    if (search?.length) {
      const results = usersListForSearch.filter(
        (user: { name: string; middleName: string; surname: string }) =>
          removeSpaces(
            `${user.name.toLowerCase()}${user?.middleName.toLowerCase()}${user.surname.toLowerCase()}`,
          ).includes(removeSpaces(search.toLowerCase())),
      );
      setUsersListBox(results);
    } else if (search !== undefined) {
      setUsersListBox(usersListForSearch);
    }
  }, [search]);

  const checkSelectedFromList = useMemo(() => {
    const selectedUsersSet = new Set(selectedUsers);
    return usersListForSearch?.some((user: { _id: string }) =>
      selectedUsersSet.has(user._id),
    );
  }, [selectedUsers, usersListForSearch]);

  return (
    <div>
      <div className={"flex relative items-center justify-center"}>
        {!usersExceptSearch || loading ? (
          <Spinner />
        ) : (
          usersExceptSearch.map((member: typeMember, index: number) => (
            <Fragment key={index}>
              <Tooltip
                containerClassName={"[&:not(:first-child)]:ml-[-6px]"}
                toggler={
                  <div
                    onClick={() => handleSelectUser(member._id)}
                    className={`rounded-full cursor-pointer ${selectedUsers.includes(member._id) ? "outline outline-2 outline-main-color" : ""}`}
                  >
                    <Avatar
                      content={`${member.name} ${member.surname}`}
                      avatar={member.avatar}
                      size={40}
                      containerClass={`border-[2px] border-white bg-z-grey-450`}
                    />
                  </div>
                }
                content={
                  <p className="text-sm font-normal">
                    {member.name} {member.middleName} {member.surname}{" "}
                  </p>
                }
              />
            </Fragment>
          ))
        )}
        {filtersOptions?.companyUsers?.length > 5 ? (
          <div className={`rounded-full cursor-pointer relative border-black`}>
            <CustomDropdown
              customToggler={
                <span
                  className={`text-main-color flex items-center justify-center ml-[-6px] font-medium w-[40px] h-[40px] rounded-full bg-white ${checkSelectedFromList ? "outline outline-2 outline-main-color" : ""}`}
                >
                  <span
                    className={`border-[2px] border-white text-main-color w-full h-full rounded-full flex justify-center items-center bg-z-grey-150`}
                  >
                    +{filtersOptions?.companyUsers?.length - 5}
                  </span>
                </span>
              }
              customContent={
                <div
                  className={"bg-white w-[300px] max-h-[280px] flex flex-col"}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ScrollBar
                    id="scrollBarContainerBox"
                    component="div"
                    className={
                      "bg-white w-[300px] max-h-[240px] flex flex-col gap-3 px-[15px] pt-[15px]"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    options={{
                      wheelPropagation: true,
                    }}
                  >
                    {usersListBox?.length ? (
                      usersListBox?.map((member: typeMember, index: number) => (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectUser(member._id);
                          }}
                          key={index}
                          className={
                            "flex justify-between items-center cursor-pointer gap-[2px]"
                          }
                        >
                          <div className={"flex gap-2 items-center"}>
                            <Avatar
                              content={`${member.name} ${member.surname}`}
                              avatar={member.avatar}
                              size={40}
                              containerClass={
                                "border-[2px] border-white bg-z-grey-450"
                              }
                            />
                            <span
                              className={"text-z-grey-450 text-sm font-[500]"}
                            >
                              {member.name} {member.middleName} {member.surname}
                            </span>
                          </div>
                          <CheckBox
                            text=""
                            isChecked={selectedUsers.includes(member._id)}
                            onChange={(value) => handleSelectUser(member._id)}
                            className={`${!selectedUsers.includes(member._id) ? "bg-white border border-z-grey-350" : ""}`}
                          />
                        </div>
                      ))
                    ) : (
                      <span
                        className={
                          "text-z-grey-450 text-sm font-[500] w-full text-center pb-1"
                        }
                      >
                        No members...
                      </span>
                    )}
                  </ScrollBar>
                  <div
                    className={
                      "w-full flex justify-center items-center py-[8px]"
                    }
                  >
                    <div
                      className={
                        "rounded-[10px] border border-z-grey-400 flex justify-center items-center max-w-[90%] overflow-hidden"
                      }
                    >
                      <SearchIco className={"w-[25px] ml-1 mt-[1px]"} />
                      <input
                        type="text"
                        value={search || ""}
                        onChange={(e) => searchFunction(e.target.value)}
                        placeholder={"Search Members"}
                        className={`
                           text-z-grey-600  pb-1 pt-[5px] pr-2 pl-1 text-base border-y border-transparent focus:outline-none focus:border-transparent focus:placeholder:text-transparent font-[400] w-full`}
                      />
                    </div>
                  </div>
                </div>
              }
              menuClassName={
                "!p-0 !rounded-[8px] border border-z-grey-400 !py-0 "
              }
            />
          </div>
        ) : null}
      </div>
      {!hideInfo ? (
        <CustomNote type="warning" className="mt-4">
          <p>You can only select one memeber here.</p>
        </CustomNote>
      ) : null}
    </div>
  );
};

export default ApplicantTransferFollow;
