import { createSlice } from "@reduxjs/toolkit";
import {
  activateDeactivateUser,
  createAdminUser,
  createUserEmailAddress,
  getUsers,
  updateUserPermissions,
  updateUserRoleTemplate,
} from "./users.actions";
import {
  usersActionType,
  usersFormReducerType,
  usersInformationType,
  usersListReducerType,
} from "../users.types";
interface ReducerType {
  usersList: usersListReducerType;
  usersForm: usersFormReducerType;
  userAction: usersActionType;
  usersInformation: usersInformationType;
}

const initialState: ReducerType = {
  usersList: {
    values: null,
    loading: true,
    error: null,
  },
  usersForm: {
    processing: false,
    navigate: false,
    error: null,
  },
  userAction: {
    loading: false,
    error: null,
  },
  usersInformation: {
    basic_info: null,
    permissions: null,
    template: null,
    company: null,
  },
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsersList: (state) => {
      state.usersList = initialState.usersList;
    },
    clearUsersForm: (state) => {
      state.usersForm = initialState.usersForm;
    },
    addUserInformation: (state, action) => {
      state.usersInformation.basic_info = action.payload;
    },
    addUserPermissions: (state, action) => {
      state.usersInformation.permissions = action.payload;
    },
    addUserFormTemplate: (state, action) => {
      state.usersInformation.template = action.payload;
    },
    addUserFormCompany: (state, action) => {
      state.usersInformation.company = action.payload;
    },
    clearUserInformation: (state) => {
      state.usersInformation = initialState.usersInformation;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.usersList.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.usersList.values = payload;
        state.usersList.loading = false;
      })
      .addCase(getUsers.rejected, (state, { payload }: any) => {
        state.usersList.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.usersList.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(activateDeactivateUser.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(activateDeactivateUser.fulfilled, (state, { payload }) => {
        state.usersList.values = {
          ...state.usersList.values,
          data: payload,
        };
        state.userAction.loading = false;
      })
      .addCase(activateDeactivateUser.rejected, (state, { payload }) => {
        state.userAction.error = payload;
        state.userAction.loading = false;
      })
      .addCase(updateUserPermissions.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(updateUserPermissions.fulfilled, (state, { payload }) => {
        state.usersList.values = {
          ...state.usersList.values,
          data: payload,
        };
        state.userAction.loading = false;
      })
      .addCase(updateUserPermissions.rejected, (state, { payload }) => {
        state.userAction.error = payload;
        state.userAction.loading = false;
      })

      .addCase(updateUserRoleTemplate.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(updateUserRoleTemplate.fulfilled, (state, { payload }) => {
        state.usersList.values = {
          ...state.usersList.values,
          data: payload,
        };
        state.userAction.loading = false;
      })
      .addCase(updateUserRoleTemplate.rejected, (state, { payload }) => {
        state.userAction.error = payload;
        state.userAction.loading = false;
      })

      .addCase(createAdminUser.pending, (state) => {
        state.usersForm.processing = true;
      })
      .addCase(createAdminUser.fulfilled, (state) => {
        state.usersForm.navigate = true;
        state.usersForm.processing = false;
        state.usersForm.error = initialState.usersForm.error;
      })
      .addCase(createAdminUser.rejected, (state, { payload }) => {
        state.usersForm.processing = false;
        state.usersForm.error = payload;
      })
      .addCase(createUserEmailAddress.pending, (state) => {
        state.userAction.loading = true;
      })
      .addCase(createUserEmailAddress.fulfilled, (state, { payload }) => {
        state.userAction.loading = false;
        state.usersList.values = payload
          ? {
              ...state.usersList.values,
              data: payload,
            }
          : state.usersList.values;
        state.userAction.error = initialState.userAction.error;
      })
      .addCase(createUserEmailAddress.rejected, (state, { payload }) => {
        state.userAction.loading = false;
        state.userAction.error = payload;
      });
  },
});

export const {
  clearUsersForm,
  clearUsersList,
  addUserInformation,
  addUserPermissions,
  addUserFormTemplate,
  addUserFormCompany,
  clearUserInformation,
} = users.actions;

export default users.reducer;
