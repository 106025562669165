import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const UsersWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/UsersWrapper/UsersWrapper"),
    ),
);

const Users: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/Users/Users")),
);

const UsersWizard: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../pages/UsersWizard/UsersWizard"),
    ),
);

const usersRoutes: routeType[] = [
  {
    path: "/users",
    element: <UsersWrapper />,
    permissions: "GET_USERS",
    meta: {
      layout: "app",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
    children: [
      {
        path: "",
        element: <Users />,
        meta: {
          title: "Users",
          type: "component",
          private: true,
          company: true,
          roles: ["zenhire"],
        },
      },
    ],
  },

  {
    path: "/add-user",
    element: <UsersWizard />,
    permissions: "CREATE_USER",
    meta: {
      layout: "app",
      title: "Users Wizard",
      type: "page",
      private: true,
      company: true,
      roles: ["zenhire"],
    },
  },
];

export default usersRoutes;
