import { getInitials, renderFileUrl } from "common/helpers/functions.helpers";
import { ImagePreview } from "../FilePreview/ImagePreview";

const Avatar = ({
  content,
  avatar,
  size,
  Icon,
  containerClass,
  imageClass,
  styleContainer,
  renderWithImgTag,
}: {
  content?: string;
  avatar?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  size?: number;
  containerClass?: string;
  imageClass?: string;
  styleContainer?: any;
  renderWithImgTag?: boolean;
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full text-white ${!avatar ? "bg-main-color" : "bg-white"} ${containerClass}`}
      style={{
        width: size ? `${size}px` : "42px",
        height: size ? `${size}px` : "42px",
        minWidth: size ? `${size}px` : "42px",
        maxHeight: size ? `${size}px` : "42px",
        ...styleContainer,
      }}
    >
      {avatar ? (
        renderWithImgTag ? (
          <img
            src={renderFileUrl(avatar)}
            alt={"avatar"}
            className={`rounded-full object-cover select-none ${
              imageClass ? imageClass : ""
            }`}
            style={{
              width: size ? `${size}px` : "42px",
              height: size ? `${size}px` : "42px",
              minWidth: size ? `${size}px` : "42px",
              maxHeight: size ? `${size}px` : "42px",
            }}
          />
        ) : (
          <ImagePreview
            src={renderFileUrl(avatar)}
            alt={"avatar"}
            className={`rounded-full object-cover select-none ${
              imageClass ? imageClass : ""
            }`}
            style={{
              width: size ? `${size}px` : "42px",
              height: size ? `${size}px` : "42px",
              minWidth: size ? `${size}px` : "42px",
              maxHeight: size ? `${size}px` : "42px",
            }}
          />
        )
      ) : Icon ? (
        <Icon
          className={`[&_path]:fill-white ${imageClass ? imageClass : ""}`}
          style={{
            width: size ? `${size / 2}px` : "25px",
            height: size ? `${size / 2}px` : "25px",
            minWidth: size ? `${size / 2}px` : "25px",
            maxHeight: size ? `${size / 2}px` : "25px",
          }}
        />
      ) : content ? (
        <p
          style={{
            fontSize: size ? `${size / 2.5}px` : "21px",
          }}
          className="select-none mt-[1px]"
        >
          {getInitials(content)}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Avatar;
